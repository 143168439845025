<template>
	<div class="fullpage-container">
		<div class="mouse-scroll"><span>scroll</span></div>
		<!-- indicator -->
		<div class="fullpage-pagination">
			<button type="button" class="fp-pagination-item" :class="{ 'is-active': currentIndex === index }" @click="moveToPage(index)" v-for="(item, index) in pageNum" :key="index">
				<i></i>
				<span>{{ pagination[index] }}</span>
			</button>
		</div>
		<!-- //indicator -->
		<div class="fullpage-wp" v-fullpage="opts" ref="fullpageRef">
			<slot></slot>
		</div>

		<!-- 풀페이지용 top 버튼  -->
		<button type="button" class="fullpage-btn-top" @click="moveToPage(0)"></button>
	</div>
</template>

<script>
import Vue from 'vue';
import 'animate.css';
import 'fullpage-vue2/src/fullpage.css';
import VueFullpage from 'fullpage-vue2';
Vue.use(VueFullpage);
/*
.fp-normal-scroll : fullpage 스크롤이 작동하는 영역내에서 스크롤이 필요한 영역내에 이 클래스를 할당하면 스크롤 작동
*/
let self;
export default {
	name: 'FullPageVue',
	props: {
		isMobile: {
			type: Boolean,
			default: false,
		},
		pagination: {
			type: Array,
			default: [],
		},
	},
	components: {},
	computed: {},
	created() {
		self = this;
	},
	data: () => ({
		currentIndex: 0,
		pageNum: 0,

		opts: {
			start: 0,
			loop: false,
			dir: 'v',
			duration: 3000,
			interval: 700,
			disabled: false,
			overflow: 'auto',
			beforeChange: (currentSlideEl, currenIndex, nextIndex) => {
				self.currentIndex = nextIndex;
				const currentClass = currentSlideEl.classList;
				if (currentClass.contains('__fp-light')) {
					document.querySelector('html').classList.add('__fullpage-light');
				} else {
					document.querySelector('html').classList.remove('__fullpage-light');
				}
			},
			afterChange: (currentSlideEl, currenIndex) => {
				// console.log('afterChange', currentSlideEl, currenIndex);
			},
		},
	}),
	methods: {
		calculateNums() {
			let sections = document.getElementsByClassName('fullpage');
			this.pageNum = sections.length;
		},

		moveToPage(id, force = false) {
			this.$refs.fullpageRef.$fullpage.moveTo(id /*move to index*/, true /*animatied*/, force /*force move?*/);
		},

		handleListScroll(e) {
			if (this.activeSection === 0 && window.scrollY > 930) {
				// console.log('뒤로가기 스크롤 유지');
				// offset 에 있는 값중에 가장 가까운 값의 인덱스로 activeSection 설정
				const targetIdx = this.offsets.reduce((prev, curr) => {
					return Math.abs(curr - window.scrollY) < Math.abs(prev - window.scrollY) ? curr : prev;
				});
				this.activeSection = this.offsets.indexOf(targetIdx) - 1;
			}
		},
		updateDisabled() {
			if (this.isMobile || window.innerWidth <= 1327) {
				this.$refs.fullpageRef.$fullpage.disabled = true;
				document.querySelector('html').classList.remove('__fullpage');
				document.querySelector('html').classList.add('__height-auto');
			} else {
				this.$refs.fullpageRef.$fullpage.disabled = false;
				document.querySelector('html').classList.add('__fullpage');
				document.querySelector('html').classList.remove('__height-auto');
			}
		},
		checkAndReInitFullPage() {
			const container = this.$el.querySelector('.fullpage-wp');
			if (!container) return;
			
			const browserHeight = window.innerHeight;
			const contentHeight = container.scrollHeight;
			
			if (Math.abs(browserHeight - contentHeight) > 10) {
				// 리사이즈 이벤트 발생 후 재초기화
				window.dispatchEvent(new Event('resize'));
				this.$nextTick(() => {
					this.$refs.fullpageRef.$fullpage.init();
				});
			}
		},
	},

	mounted() {
		this.$nextTick(() => {
			document.querySelector('html').classList.add('__fullpage');

			const sections = document.querySelectorAll('.fullpage');
			if (sections[this.currentIndex].classList.contains('__fp-light')) {
				document.querySelector('html').classList.add('__fullpage-light');
			}

			this.calculateNums();
			this.updateDisabled();
			window.addEventListener('resize', this.updateDisabled);

			// 모든 리소스가 로드된 후 높이 체크
			if (document.readyState === 'complete') {
				this.checkAndReInitFullPage();
			} else {
				window.addEventListener('load', () => {
					this.checkAndReInitFullPage();
				});
			}
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateDisabled);
	},

	destroyed() {
		document.querySelector('html').classList.remove('__fullpage');
		document.querySelector('html').classList.remove('__height-auto');
	},
};
</script>
